import DateFnsUtils from '@date-io/date-fns';
import { Grid, Paper } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useStyles } from '@root/pages/TaxInvoices/styles';
import urlsApi from '@root/services/api/apiUrls';
import apiClient from '@root/services/api/client';
import React, { useCallback, useEffect } from 'react';
import calendar from '../../assests/images/calendar.svg';
import { useStandardTableStyles } from '../../components/DefaultTable';
import DocumentIframeModal from '../../components/Iframe';
import Layout from '../../components/Layout';
import Title from '../../components/Title';
import getTaxInvoice, {
  ITaxInvoiceItem,
  ITaxInvoiceParams,
} from '../../services/api/requests/get-tax-invoices';
import { IMeta } from '../../services/types';
import Invoices from './table';
import { useSelector } from 'react-redux';
import { selectUserData } from '@root/store/system/selectors';

const defaultMeta: IMeta = {
  limit: 0,
  page: 1,
  total: 0,
  totalPages: 0,
};

type PaginationValues = 5 | 10 | 25;

const InvoicesPage: React.FC = () => {
  const classes = { ...useStandardTableStyles(), ...useStyles() };
  const systemUserData = useSelector(selectUserData);
  const [url, setUrl] = React.useState<string>('');
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [list, setList] = React.useState<ITaxInvoiceItem[]>([]);
  const [meta, setMeta] = React.useState<IMeta>(defaultMeta);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [startPeriod, setStartPeriod] = React.useState<MaterialUiPickersDate | null>(null);
  const [endPeriod, setEndPeriod] = React.useState<MaterialUiPickersDate | null>(null);
  const [isFirstUpdate, setIsFirstUpdate] = React.useState<boolean>(true);

  useEffect(() => {
    (async () => {
      if (!systemUserData?.userId) return;

      setLoading(true);
      const params: ITaxInvoiceParams = {
        page,
        limit: rowsPerPage,
      };
      if (!isFirstUpdate) {
        if (startPeriod) {
          params.startDate = new Date(startPeriod).toISOString();
        }
        if (endPeriod) {
          params.endDate = new Date(endPeriod).toISOString();
        }
      }

      const response = await getTaxInvoice(params);
      setList(response.items);
      setMeta(response.meta);
      setLoading(false);
      setIsFirstUpdate(false);
    })();
  }, [systemUserData?.userId, page, rowsPerPage, startPeriod, endPeriod]);

  useEffect(() => {
    const now = new Date();
    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

    setStartPeriod(firstDayOfMonth);
    setEndPeriod(now);
  }, []);

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10) as PaginationValues);
    setPage(1);
  };

  const handleDownloadInvoice = useCallback(async (invoiceId: number) => {
    try {
      const response = await apiClient.get(
        urlsApi.downloadTaxInvoice.replace('{id}', invoiceId.toString()),
        {
          responseType: 'blob',
        }
      );

      if (response?.status !== 200) {
        throw new Error(`Error uploading the file: ${response.statusText}`);
      }

      const invoiceBlob = new Blob([response.data]);
      const imageURL = URL.createObjectURL(invoiceBlob);

      const anchor = document.createElement('a');
      anchor.href = imageURL;
      anchor.download = `invoice_${invoiceId}.pdf`;

      document.body.appendChild(anchor);
      anchor.click();
      anchor.remove();

      URL.revokeObjectURL(imageURL);
    } catch (err) {
      console.error(`Error downloading the invoice: ${err}`);
    }
  }, []);

  const handleOpenInvoice = useCallback(async (invoiceId: number) => {
    try {
      const response = await apiClient.get(
        urlsApi.downloadTaxInvoice.replace('{id}', invoiceId.toString()),
        {
          responseType: 'blob',
        }
      );

      if (response?.status !== 200) {
        throw new Error(`Error opening the file: ${response.statusText}`);
      }

      const invoiceBlob = new Blob([response.data], { type: 'application/pdf' });
      const invoiceURL = URL.createObjectURL(invoiceBlob);

      const anchor = document.createElement('a');
      anchor.href = invoiceURL;
      if (/iPhone|iPad|iPod/i.test(window.navigator.userAgent)) {
        anchor.target = '_self';
      } else {
        anchor.target = '_blank';
      }

      document.body.appendChild(anchor);
      anchor.click();
      anchor.remove();

      URL.revokeObjectURL(invoiceURL);
    } catch (err) {
      console.error(`Error opening the invoice: ${err}`);
    }
  }, []);

  return (
    <Layout>
      <div className={classes.container}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div className={`${classes.paperHeader} ${classes.taxInvoicePaperHeader}`}>
              <Title>Tax Invoices</Title>
              <Box display="flex" className={classes.taxInvoiceDateInputWrapper}>
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.taxInvoiceDataLabelWrapper}
                >
                  <span className={classes.taxInvoiceDataLabel}>From date </span>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      disableFuture
                      label=""
                      format="dd/MM/yyyy"
                      value={startPeriod}
                      onChange={(date: MaterialUiPickersDate | null) => setStartPeriod(date)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      rightArrowIcon={<img src={calendar} alt="cal" />}
                      InputProps={{ className: classes.calendarInput }}
                      className={classes.calendarWrapper}
                    />
                  </MuiPickersUtilsProvider>
                </Box>

                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.taxInvoiceDataLabelWrapper}
                >
                  <span className={classes.taxInvoiceDataLabel}>To date </span>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      disableFuture
                      label=""
                      format="dd/MM/yyyy"
                      value={endPeriod}
                      onChange={(date: MaterialUiPickersDate | null) => setEndPeriod(date)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      rightArrowIcon={<img src={calendar} alt="cal" />}
                      InputProps={{ className: classes.calendarInput }}
                      className={classes.calendarWrapper}
                    />
                  </MuiPickersUtilsProvider>
                </Box>
              </Box>
            </div>
          </Paper>
        </Grid>

        <Paper className={classes.paper}>
          <Invoices
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            downloadInvoice={handleDownloadInvoice}
            openInvoice={handleOpenInvoice}
            meta={meta}
            list={list}
            loading={loading}
          />
        </Paper>

        <DocumentIframeModal close={() => setUrl('')} url={url} />
      </div>
    </Layout>
  );
};

export default InvoicesPage;
